const LogoSVG = () => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378.84 91.15">
        <g>
            <path d="M284.86,219.94a34.83,34.83,0,0,0-4.77-.65c-5.8-.88-11.28-2.58-15.78-6.54-4.79-4.22-6.86-9.69-6.91-16,0-1-.41-2.29.24-2.9s1.79-.16,2.72-.16c5.58,0,11.17,0,16.75,0,.57,0,.94,0,1,.76.47,5.5,3.36,7.69,8.17,8.61a30,30,0,0,0,15.55-.71c2.31-.8,4-2.24,4.39-4.85.33-2.15-.21-3.28-2.19-4.29a37.57,37.57,0,0,0-10.14-3,93.94,93.94,0,0,1-14.41-3.48c-3.79-1.37-7.39-3.07-10.29-6a13.62,13.62,0,0,1-4-10.41c.28-8.75,4.32-15.41,11.57-20.18,6.59-4.33,14-6.07,21.76-6.39a51.92,51.92,0,0,1,15.42,1.31c5.6,1.47,10.54,4,13.83,9a19.15,19.15,0,0,1,2.8,13.37c-.1.75-.6.49-1,.49H312.54a5.35,5.35,0,0,1-2.35-.12c-.72-.39-.28-1.44-.39-2.2a5.66,5.66,0,0,0-4.58-4.9,25.57,25.57,0,0,0-13.31,0,6.46,6.46,0,0,0-4.57,3.5c-.93,2.08-.47,3.32,1.6,4.29a29.76,29.76,0,0,0,6.18,1.94c4.83,1.1,9.73,1.82,14.48,3.26s9.67,3.22,13.61,6.63a13.32,13.32,0,0,1,4.94,10c.3,10.62-4.34,18.53-13.42,23.88-5.62,3.3-11.83,4.7-18.26,5.33a9.77,9.77,0,0,0-3.13.41h-2a19.83,19.83,0,0,0-5.22,0Z" transform="translate(-108.64 -128.79)" fill="#010101"/>
            <path d="M286.16,219.94a19.83,19.83,0,0,1,5.22,0Z" transform="translate(-108.64 -128.79)" fill="#1e71b8"/>
            <path d="M175.09,145.07q12.51,0,25,0a2,2,0,0,1,2.11,1.17,1.86,1.86,0,0,1-.58,2.24,46,46,0,0,1-10.55,8.23,43,43,0,0,1-14.17,5.16,32.85,32.85,0,0,1-5.51.53c-7.14,0-14.28,0-21.42,0-6.41,0-11.75,2.58-16.35,6.9a23.46,23.46,0,0,0-6.13,9.19c-1.87,5.22-2,10.4.92,15.34a14.42,14.42,0,0,0,10.93,7c3.67.53,7.38.14,11.07.22,1.27,0,2.54,0,3.81,0a1.89,1.89,0,0,0,2.09-1.64c.46-2.34.9-4.68,1.3-7,.27-1.63-.47-2.45-2.17-2.46-3.51,0-7,0-10.54,0-3.81,0-6.71-2.36-7.25-5.73a9.58,9.58,0,0,1,4.42-9.15,10.7,10.7,0,0,1,5.66-1.5q14.79.06,29.58,0c.93,0,1.28,0,1.05,1.19-1.44,7.36-2.78,14.75-4.15,22.13q-1.91,10.26-3.78,20.54c-.13.75-.4,1-1.23,1-9.93,0-19.87,0-29.8,0-7.58,0-14.49-2.12-20.36-7a27.91,27.91,0,0,1-10.12-18.66c-1.64-13.91,3.68-25.27,13.35-34.87a44.19,44.19,0,0,1,19-11.13,36.73,36.73,0,0,1,10.58-1.62C159.58,145.1,167.34,145.07,175.09,145.07Z" transform="translate(-108.64 -128.79)" fill="#010101"/>
            <path d="M312.15,129.41l99.49.34,57.08.22q8.8,0,17.61.11c.38,0,.89-.21,1.09.21s-.28.69-.55,1a29.36,29.36,0,0,1-16.08,8.37,10.11,10.11,0,0,1-1.74.11l-72.52-.37-76-.42q-43.28-.24-86.55-.44l-77.73-.44c-3.3,0-6.6-.13-9.9-.08a40,40,0,0,0-22,6.78,38.11,38.11,0,0,0-10.37,10,2,2,0,0,1-.36.41c-.4.34-.79.86-1.39.64s-.72-.86-.73-1.45a20.64,20.64,0,0,1,1.89-8.94c3.8-8.51,10.51-13.58,19.4-15.9a25.22,25.22,0,0,1,6.56-.76l84.26.31,57.19.21c10.44,0,20.88,0,31.31,0Z" transform="translate(-108.64 -128.79)" fill="#1e71b8"/>
            <path d="M402.3,145.11l-2.26,12c-.27,1.42-.59,2.83-.76,4.26-.11.88-.46,1.09-1.31,1.09-12.9,0-25.8,0-38.71,0-.93,0-1.23.28-1.35,1.15-.41,2.75-.84,5.5-1.37,8.23-.17.87,0,1,.84,1,8,0,15.94,0,23.92,0,1.48,0,1.49,0,1.22,1.46-.93,5.18-1.9,10.36-2.78,15.55-.15.92-.54,1-1.27,1-8.09,0-16.17,0-24.25,0-.93,0-1.18.27-1.34,1.17-.49,2.69-1.09,5.37-1.73,8-.21.9-.12,1.12.87,1.11q19.37,0,38.71,0c1.28,0,1.25,0,1,1.28-.92,5-1.85,9.93-2.73,14.91-.16.94-.46,1.25-1.49,1.25q-29.73-.06-59.47,0c-1.28,0-1.28,0-1.05-1.26q1.85-9.79,3.68-19.58c1.31-7,2.59-14,3.89-21s2.65-14.18,4-21.28c.58-3.08,1.16-6.17,1.66-9.27.14-.83.41-1.07,1.29-1.07,11.77,0,23.55,0,35.33,0h24.68C401.74,145.07,402,145.1,402.3,145.11Z" transform="translate(-108.64 -128.79)" fill="#010101"/>
            <path d="M173.34,218.47c3.24-5.49,6.35-10.76,9.45-16q5.55-9.41,11.08-18.82t11.1-18.8q5.5-9.36,11-18.74a1.74,1.74,0,0,1,1.76-1c7.39,0,14.78,0,22.17,0,.74,0,1.11.1,1.31,1,1.94,8.28,3.95,16.54,5.93,24.81q2.56,10.72,5.11,21.45,3,12.63,6,25.24c.18.76.06,1-.76,1q-10.27,0-20.54,0c-.69,0-.93-.22-1.07-.87-1-4.65-2.05-9.29-3.06-13.94-.15-.71-.4-1-1.19-1q-13.22,0-26.42,0a1.28,1.28,0,0,0-1.33.75c-2.63,4.68-5.33,9.32-8,14a1.75,1.75,0,0,1-1.77,1c-6.52,0-13,0-19.57,0Zm51.79-54c-3.9,7-7.73,13.83-11.62,20.72a1.71,1.71,0,0,0,.37.09c5,0,9.92,0,14.88,0,.8,0,.65-.36.54-.87q-1.38-6.39-2.74-12.78C226.06,169.24,225.6,166.84,225.13,164.45Z" transform="translate(-108.64 -128.79)" fill="#010101"/>
            <path d="M390.8,218.4c3.39-3.62,6.68-7.11,10-10.6l21.53-22.89c1.12-1.19,2.23-2.37,3.37-3.52a1,1,0,0,0,.18-1.38q-5.62-9.62-11.2-19.25-4.51-7.75-9-15.51c.25-.31.56-.17.82-.17,7.57,0,15.14,0,22.72,0a1.26,1.26,0,0,1,1.33.78q5.28,9.67,10.61,19.32c.17.31.27.68.68.9l6.46-7,12.59-13.59a1.19,1.19,0,0,1,.93-.44q11.36,0,22.72,0c.06,0,.12,0,.32.14l-2.91,3L467,163.54c-5.33,5.5-10.65,11-16,16.5-.49.5-.3.85,0,1.3q2.86,5,5.72,9.93l14.13,24.66,1.32,2.32c-.23.36-.55.21-.81.21-7.43,0-14.85,0-22.28,0a1.27,1.27,0,0,1-1.28-.73q-5.68-10.5-11.4-21c-.14-.25-.28-.5-.41-.76-.22-.45-.45-.46-.78-.09-1.75,2-3.5,3.94-5.26,5.91l-11,12.27c-1.14,1.27-2.27,2.53-3.39,3.81a1.45,1.45,0,0,1-1.19.57q-11.53,0-23.05,0C391.21,218.47,391.1,218.44,390.8,218.4Z" transform="translate(-108.64 -128.79)" fill="#010101"/>
        </g>
        </svg>
     );
}
 
export default LogoSVG;